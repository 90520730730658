import React from 'react';
import { ResponsiveAppBar } from '../components/navigation';
import { Grid, styled , ThemeProvider, Typography,Button} from '@mui/material';
import Paper from '@mui/material/Paper';
import SendIcon from '@mui/icons-material/Send';

import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const theme = createTheme({
    typography: {
      // Tell Material UI what the font-size on the html element is.ß
      fontFamily: [
        'Courier',
        'sans-serif'
      ].join(',')
    },
  });

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

const Home = () => {
  return (
    <>
    <ThemeProvider theme={theme}>

     <ResponsiveAppBar />
    
     <Grid container spacing={2} style={{marginTop:'8px',padding:'20px', backgroundColor:'#171d22'}}>
            <Grid item xs={0} md={2}>
                
            </Grid>
            <Grid item xs={12} md={10}>
                <Item style={{ backgroundColor:'#171d22', color:'white'}}>
                    <Typography variant="h4" component="div" gutterBottom>
                       Hi,<br/> I'm a full-stack developer <br/> & experienced startup CTO
                    </Typography>


                    <Grid container spacing={3} style={{marginTop:'20px'}}>
                        <Grid item xs={12} md={2}>
                        <img style={{ maxWidth:'100%', maxHeight:'100%', borderRadius: '5%'}} src={window.location.origin + '/images/KD_image2.jpeg'} alt="image" />
                        
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <br/>
                            <Typography variant="h5" component="div" gutterBottom>
                                With over a decade of experience, <br/> I've worked with a variety of technologies and platforms. <br/>I'm passionate about building great products and teams.
                            </Typography>
                            <br/>
                            <Typography variant="h6" component="div" gutterBottom>
                              Currently seeking my next opportunity. <br/> If you're looking for a full-stack developer or CTO, I'd love to chat.
                              </Typography>
                              <br/>
                              <Button variant="contained"  endIcon={<SendIcon />}>Let's Chat</Button><Button variant="outlined" style={{color:'white'}}>Learn More</Button>
                        </Grid>
                    </Grid>

                    
                </Item>
            </Grid>
           
            <Grid item xs={2}></Grid>
        </Grid>
    </ThemeProvider>
   </>
  );
}

export default Home;