import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function ContactModal({button, handleOpen, handleClose, isOpen }) {

  
  

  return (
  
    <div>
      {button}
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h4" component="h4">
            Contact Me
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
           Email: <a href='mailto:kieran.desmond@gmail.com?subject=Query via Kierandesmond.me'>kieran.desmond@gmail.com</a><br/>
           Phone: <a href='tel:00353879254256'>+353-879254256</a><br/>
           LinkedIn: <a href='https://linkedin.com/in/kierandesmond'>My Profile</a>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
export default  ContactModal;