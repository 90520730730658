import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import MoodIcon from '@mui/icons-material/Mood';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Beenhere } from '@mui/icons-material';
import GradingIcon from '@mui/icons-material/Grading';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { Link } from "react-router-dom";

export default function SwipeableTemporaryDrawer() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  let link ='/' ;
  let links =[ '/','/projects','https://linkedin.com/in/kierandesmond','/worktogether','/contact']

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>

        {['About', 'Projects', 'Resume','Work with Me', 'Contact'].map((text, index) => (
          <ListItem key={text} disablePadding>
            
            <ListItemButton component={Link} to={links[index]}>
              <ListItemIcon>
               
                {index === 0 ? <AccountCircleIcon /> :''}
                {index === 1 ? <Beenhere /> : ''}
                {index === 2 ? <GradingIcon /> : ''}
                {index === 3 ? <GroupAddIcon /> : ''}
                {index === 4 ? <ConnectWithoutContactIcon /> : ''}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      
    </Box>
  );

  return (
    <div>
      {['top'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>  <MenuIcon></MenuIcon></Button>
          <SwipeableDrawer
            anchor={'top'}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}