import  React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import SwipeableTemporaryDrawer from './menudrawer';

import { styled } from '@mui/material'
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import ForumIcon from '@mui/icons-material/Forum';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Padding } from '@mui/icons-material';
import Man2Icon from '@mui/icons-material/Man2';
import MoodIcon from '@mui/icons-material/Mood';
import { Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import Hidden from '@mui/material/Hidden';
import ContactModal from './contactmodal';

const pages = ['About', 'CountDown', 'Blog'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


export function ResponsiveAppBar() {

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const styles = styled({
    bar:{       // class name
      paddingTop: "1.15rem",
      backgroundColor: "#fff",
      ['@media (max-width:780px)']:{flexMedia: "column"}, // media queries - for different devices and web-browser sizes
    },
    // logo: {
    //   width: "15%", 
    //   ['@media (max-width:780px)']: {display: "none"},
    // },
    // logoMobile:{
    //   width: "100%", 
    //   display: "none", 
    //   ['@media (max-width:780px)']: {display: "inline-block"},
    // },
    menuItem: {
      cursor: "pointer", 
      flexGrow: 1,
      "&:hover": {color:  "#4f25c8"},
      ['@media (max-width:780px)']: {paddingBottom: "1rem"},
    },
    centeredLabel: {
        display: "flex", justifyContent: "center", alignItems: "middle",verticalAlign:"middle"
      },
      menuText: {
        display: 'flex',
        alignItems: 'middle',
      },
      iconAlignment1:
        {
            verticalAlign: "middle"
        }

})
const classes = styles;
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};



const CustomToolbar = styled(Toolbar)(({ theme }) => ({
    paddingTop: '1.15rem',
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    position: 'sticky',
    // 👇 Optional: consider to use theme.breakpoints for this
    ['@media (max-width:780px)']: { flexDirection: 'column' },
  }));

  // 👇 Only when theme is not needed
const CustomToolbarItem = styled(Typography)({
    cursor: 'pointer',
    flexGrow: 1,
    '&:hover': { color: '#4f25c8' },
    ['@media (max-width:780px)']: { paddingBottom: '1rem' },
  });

  return (
    <AppBar position="static">

        <CustomToolbar disableGutters className={classes.bar} >
        <Grid container spacing={2} style={{marginTop:'20px'}}>
            <Grid item xs={12} sm={3} md={2} >
       
                <Item>
                <Box sx={{ display: { xl: 'none', md:'none', sm:'block', xs: 'block' } }}><div ><SwipeableTemporaryDrawer></SwipeableTemporaryDrawer></div></Box>
                <CustomToolbarItem variant="h6"><div className={classes.centeredLabel}><span ><MoodIcon style={{verticalAlign: "middle",alignContent:"center"}}  ></MoodIcon></span> <span> KieranDesmond.me</span></div></CustomToolbarItem>
                </Item>
            </Grid>
            <Grid display={{ xs: "none", lg: "block" }} item xs={2} sm={4} md={2}></Grid>
      
            <Grid display={{ xs: "none", lg: "block" }} item xs={2} sm={2} md={2}>
                <Item> <CustomToolbarItem variant="h6"><div className={classes.centeredLabel}><span ><BeenhereIcon style={{verticalAlign: "middle"}} ></BeenhereIcon></span> <span>Projects</span></div></CustomToolbarItem></Item>
            </Grid>
            <Grid display={{ xs: "none", lg: "block" }} item xs={0} sm={2} md={2}>
                <Item>  <CustomToolbarItem variant="h6"><div className={classes.centeredLabel}><span><a href="https://linkedin.com/in/kierandesmond"><AssignmentIcon className={classes.centeredLabel} style={{verticalAlign: "middle"}} ></AssignmentIcon></a></span><a href="https://linkedin.com/in/kierandesmond" style={{textDecoration:'none'}}><span> Resume</span></a></div></CustomToolbarItem>
                </Item>
            </Grid>
            <Grid display={{ xs: "none", lg: "block" }} item xs={0} sm={2} md={2}>
                <Item>  <CustomToolbarItem variant="h6"><div className={classes.centeredLabel}><span><a href="https://linkedin.com/in/kierandesmond"><AssignmentIcon className={classes.centeredLabel} style={{verticalAlign: "middle"}} ></AssignmentIcon></a></span><a href="https://linkedin.com/in/kierandesmond" style={{textDecoration:'none'}}><span> Work with Me</span></a></div></CustomToolbarItem>
                </Item>
            </Grid>
            <Grid display={{ xs: "none", lg: "block" }} item xs={0} md={2}>
                <Item><CustomToolbarItem variant="h6"><div className={classes.centeredLabel}><span className={classes.centeredLabel} >  <ContactModal handleOpen={handleOpen} handleClose={handleClose} isOpen={open} button={<Button onClick={handleOpen}  ><ForumIcon style={{verticalAlign: "middle"}} ></ForumIcon>&nbsp;Contact</Button>}></ContactModal></span></div></CustomToolbarItem>
                </Item>
            </Grid>
        </Grid>
         
            
           
           
             
      
    </CustomToolbar>
    </AppBar>
  );
}
